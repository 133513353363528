
export const list = {
  combinationmFloor: {
    infoComponentName: 'CombinationProto',

    tableOption: {
      search: true,
      btn: false,
      height: 'auto',
      menuWidth: 150,
      menuAlign: 'center',
      menu: false,
      delBtn: false,
      topPage: false,
      searchSpan: 6,
      selection: false,
      searchLabelWidth: 90,
      page: false,
      column: [
        {
          label: '自定义底板',
          prop: 'bottomBoardPreview',
          slot: true
        },
        {
          label: '时间',
          prop: 'createTime'
        }
      ]
    }
  },

  default: {
    infoComponentName: 'SingleProto',

    tableOption: {
      search: true,
      btn: false,
      height: 'auto',
      menuWidth: 150,
      menuAlign: 'center',
      menu: false,
      delBtn: false,
      topPage: false,
      searchSpan: 6,
      selection: false,
      searchLabelWidth: 90,
      page: false,
      column: [
        {
          label: '自定义底板',
          prop: 'bottomBoardPreview',
          slot: true
        },
        {
          label: '分销商',
          prop: 'nickName',
          search: true
        },
        {
          label: '时间',
          prop: 'createTime'
        }
      ]
    }
  }
}

export function getOption(type = 'default') {
  return list[type]
}
